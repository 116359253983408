import { lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import TestForm from './pages/TestForm'
import DownloadPage from './pages/DownloadPage'
const Home = lazy(() => import('./pages/Home'))

export default function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/test-form' component={TestForm} />
      <Route path='/download' exact component={DownloadPage} />
    </Switch>
  )
}