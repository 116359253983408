import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: transparent;
  height: 100vh;
  padding: 20px;
  margin: 0px 200px 0px;
  border-radius: 5px;
  color: white;

  @media (max-width: 768px) {
    margin: 10px 50px 0px;
  }

  @media (max-width: 480px) {
    margin: 10px 20px 0px;
    padding: 10px;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
`;

export const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 4px solid;
  border-color: #007bff transparent #007bff transparent;
  border-radius: 50%;
  animation: ${spin} 1.2s linear infinite;
`;

export const LoadingText = styled.p`
  color: white;
  font-size: 1rem;
  margin: 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

export const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 5px;
  color: #333;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;

  &:focus {
    border-color: #007bff;
  }
`;

export const Select = styled.select`
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;

  &:focus {
    border-color: #007bff;
  }
`;

export const Button = styled.button`
  padding: 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;