import { createGlobalStyle } from "styled-components";
import stant from '../images/stant.avif'

export default createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Sora', sans-serif;
  }

  body {
    will-change: background-image;
    background-image: url(${stant});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;    
    font-size: 16px;
    position: relative;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto; /* Garante que o corpo permita a rolagem */
  }

  body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* Cor preta com opacidade de 50% */
    z-index: -1; /* Coloca a camada preta atrás do conteúdo */
  }
`

