import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from '../../assets/styles/global'
import { MainContainer } from './styles';
import Routes from '../../Routes';

function App() {
  return (
    <BrowserRouter>
      <MainContainer>
        <Routes/>
        <GlobalStyles />
      </MainContainer>
    </BrowserRouter>

  );
}

export default App;
