import React from 'react';
import { Container, Summary, Label, Value, Button, ButtonsContainer, ButtonBack } from './styles';
import { useLocation, useHistory } from 'react-router-dom';

const DownloadPage = () => {

  const location = useLocation()
  const history = useHistory()
  const data = location.state || {}  

  const formData = data.dataToSend
  const downloadUrl = data.response.generatedUrl

  const handleBackToForm = () => {
    history.push('/test-form')
  };

  return (
    <Container>
      <h2>Sua prova foi gerada com sucesso!</h2>

      <Summary>
        <div>
          <Label>Nível da Prova:</Label>
          <Value>{formData.testLevel}</Value>
        </div>
        <div>
          <Label>Disciplina:</Label>
          <Value>{formData.subject}</Value>
        </div>
        <div>
          <Label>Quantidade de Questões de Múltipla Escolha:</Label>
          <Value>{formData.multipleQuestionsQtd}</Value>
        </div>
        <div>
          <Label>Quantidade de Questões Dissertativas:</Label>
          <Value>{formData.dissertiveQuestionsQtd}</Value>
        </div>
        <div>
          <Label>Nível de Dificuldade:</Label>
          <Value>{formData.difficulty}</Value>
        </div>
      </Summary>

      <ButtonsContainer>

        <a href={downloadUrl} download>
          <Button>Baixar prova</Button>
        </a>

        <ButtonBack onClick={handleBackToForm}>Gerar outra prova</ButtonBack>
      </ButtonsContainer>
    </Container>
  );
};

export default DownloadPage;
