import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: red;
  height: 90vh; /* Reduz a altura total do container */
  background-color: transparent;
  padding: 20px;
  margin: 0px 200px 0px; /* Reduz o espaço externo na parte de cima (10px no topo) */
  border-radius: 5px;
  color: white;

  @media (max-width: 768px) {
    margin: 10px 50px 0px; /* Reduz a margem lateral e mantém o topo em tablets */
  }

  @media (max-width: 480px) {
    margin: 10px 20px 0px; /* Reduz ainda mais a margem lateral e mantém o topo em smartphones */
    padding: 10px; /* Ajusta o padding em telas menores */
  }
`;

export const Summary = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const Label = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin: 5px 0;
`;

export const Value = styled.p`
  font-size: 1rem;
  color: #555;
  margin: 5px 0 15px 0;
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

export const ButtonBack = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #a4a424;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #909010;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px; /* Espaçamento horizontal entre os botões */
  justify-content: center;
  margin-top: 20px; /* Espaçamento acima dos botões */
  
`