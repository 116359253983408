import React, { useState } from 'react';
import axios from 'axios';
import { 
  Container, 
  Form, 
  Label, 
  Input, 
  Select, 
  Button,
  LoadingContainer,
  Spinner,
  LoadingText
} from './styles';
import { useHistory } from 'react-router-dom';

const TestForm = () => {
  const [formData, setFormData] = useState({
    testLevel: '',
    topic: '',
    subject: '',
    multipleQuestionsQtd: '',
    dissertiveQuestionsQtd: '',
    difficulty: ''
  });

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSend = {
      topic: formData.topic,
      testLevel: formData.testLevel,
      difficulty: formData.difficulty,
      subject: formData.subject,
      multipleQuestionsQtd: parseInt(formData.multipleQuestionsQtd, 10),
      dissertiveQuestionsQtd: parseInt(formData.dissertiveQuestionsQtd, 10)
    };

    try {
      const response = await axios.post('https://p4pzqinpl0.execute-api.us-east-1.amazonaws.com/prod/docs', dataToSend, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      setLoading(false);
      history.push('/download', { dataToSend, response: response.data });
    } catch (error) {
      setLoading(false);
      console.error('Erro na requisição:', error);
      if (error.response) {
        console.error('Status do erro:', error.response.status);
      }
    }
  };

  return (
    <Container>
      {loading ? (
        <>
          <h2>Gerando resumo...</h2>
          <LoadingContainer>
            <Spinner />
            <LoadingText>Gerando arquivo...</LoadingText>
          </LoadingContainer>
        </>
      ) : (
        <>
          <h2>Preencha as informações para gerar a prova</h2>
          <Form onSubmit={handleSubmit}>
            <Label htmlFor="testLevel">Nível da Prova:</Label>
            <Select name="testLevel" value={formData.testLevel} onChange={handleChange} required>
              <option value="">Selecione o nível</option>
              <option value="Ensino Fundamental">Ensino Fundamental</option>
              <option value="Ensino Médio">Ensino Médio</option>
              <option value="Ensino Superior">Ensino Superior</option>
            </Select>

            <Label htmlFor="difficulty">Nível de Dificuldade:</Label>
            <Select name="difficulty" value={formData.difficulty} onChange={handleChange} required>
              <option value="">Selecione o nível</option>
              <option value="Fácil">Fácil</option>
              <option value="Médio">Médio</option>
              <option value="Difícil">Difícil</option>
            </Select>

            <Label htmlFor="subject">Disciplina:</Label>
            <Input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Digite a disciplina"
              required
            />

            <Label htmlFor="topic">Tópico:</Label>
            <Input
              type="text"
              name="topic"
              value={formData.topic}
              onChange={handleChange}
              placeholder="Digite o tópico"
              required
            />

            <Label htmlFor="multipleQuestionsQtd">Quantidade de Questões de Múltipla Escolha:</Label>
            <Input
              type="number"
              name="multipleQuestionsQtd"
              value={formData.multipleQuestionsQtd}
              onChange={handleChange}
              placeholder="Ex: 3"
              required
            />

            <Label htmlFor="dissertiveQuestionsQtd">Quantidade de Questões Dissertativas:</Label>
            <Input
              type="number"
              name="dissertiveQuestionsQtd"
              value={formData.dissertiveQuestionsQtd}
              onChange={handleChange}
              placeholder="Ex: 2"
              required
            />

            <Button type="submit">Gerar Prova</Button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default TestForm;